<template>
  <WeContainer card="">
    <WeCard class="mb-3">
      <h5 class="custom-card-title">
        <i class="fas fa-tags"></i>
        <span>Kupon Kodu Raporları</span>
      </h5>
    </WeCard>
    <WeTableSearch
      v-on:search="onSearch"
      v-on:clear="onFilterClear"
      v-bind:show-icon="false"
      header-class="bg-white text-dark"
      header-text-class="text-dark h6"
      v-bind:show-on-load="true"
    >
      <div slot="detail-search">
        <div class="row mb-3">
          <!-- Left -->
          <div class="col-12 col-lg-6">
            <!-- Created At -->
            <div class="d-block mb-3">
              <WeRowInput
                form="date"
                size="sm"
                label="Tarih"
                placeholder="Tarih"
                v-bind:date-range="true"
                v-model="filter.dates"
                selected-shortcut="thisMonth"
              />
            </div>
            <!-- Created At -->
          </div>
          <!-- Left -->

          <!-- Right -->
          <div class="col-12 col-lg-6">
            <!-- Discount Coupons -->
            <div class="d-block mb-3">
              <WeRowInput
                form="searchSelect"
                size="sm"
                name="discountCoupon"
                label="Kupon Kodu"
                placeholder="Kupon Kodu"
                v-bind:clearable="true"
                v-model="filter.discountCoupon"
              />
            </div>
            <!-- Discount Coupons -->
          </div>
          <!-- Right -->
        </div>
      </div>
    </WeTableSearch>
    <WeCard>
      <div class="position-relative" style="min-height: 250px" v-if="loading">
        <WeLoading />
      </div>
      <div v-else v-bind:id="printConfig.id">
        <span v-print="printConfig" ref="printButton"> </span>
        <div class="text-center print">
          <h5 class="font-weight-bold">{{ getFileName }}</h5>
        </div>
        <WeTable
          v-bind:index="false"
          v-bind:columns="columns"
          v-bind:data="list"
          v-bind:actions="actions"
          v-bind:exportables="exportables"
          v-on:on-export="onExport"
          v-on:on-action="onAction"
          v-bind:paginatable="paginatable"
          v-bind:pageDetails="pageDetails"
          v-bind:limitable="limitable"
          v-bind:searchable="searchable"
        />
      </div>
    </WeCard>

    <OrderList
      v-if="showOrderList"
      v-bind:detail="detail"
      v-bind:loading="modalLoading"
      v-on:close="showOrderList = false"
    />

    <UserList
      v-if="showUserList"
      v-bind:detail="detail"
      v-bind:loading="modalLoading"
      v-on:close="showUserList = false"
    />
  </WeContainer>
</template>
<script>
import { mapActions, mapState } from "vuex";
import OrderList from "./views/OrderList/Index.vue";
import UserList from "./views/UserList/Index.vue";

export default {
  name: "DiscountCoupon",
  components: {
    OrderList,
    UserList,
  },
  data() {
    return {
      modalLoading: false,
      loading: false,
      filter: {
        dates: {
          start: null,
          end: null,
        },
        discountCoupon: null,
      },
      detail: null,
      list: [],
      showOrderList: false,
      showUserList: false,
      columns: [
        { name: "name", th: "Kupon Adı", type: "string" },
        { name: "code", th: "Kupon Kodu", type: "string" },
        { name: "start_date", th: "Başlangıç Tarihi", type: "date" },
        { name: "due_date", th: "Bitiş Tarihi", type: "date" },
        {
          name: "total_member_usage",
          th: "Faydalanan Üye Adeti",
          type: "integer",
        },
        {
          name: "total_discount",
          th: "Toplam İndirim Tutarı",
          type: "currency",
        },
        {
          name: "is_active",
          th: "Durum",
          type: "boolean_disabled",
        },
      ],
      actions: [
        {
          icon: "fas fa-shopping-basket",
          class: "btn-outline-indigo",
          action: "orders",
          tooltip: "Sipariş Listesi",
        },
        {
          icon: "fas fa-users",
          class: "btn-outline-indigo ml-2",
          action: "users",
          tooltip: "Üye Listesi",
        },
      ],
      paginatable: true,
      pageDetails: true,
      limitable: true,
      searchable: true,
      exportables: ["excel", "pdf", "print"],
      printConfig: {
        id: "DiscountCouponReport",
        popTitle: "Kupon Kodu Raporları",
      },
    };
  },
  methods: {
    ...mapActions("reports", ["makeSearch", "show"]),
    ...mapActions("tableExport", ["exportData"]),
    getFilter(copyDates = true) {
      let copy = helper.clone(this.filter);
      copy.dates = [];

      if (
        copyDates &&
        this.filter.dates &&
        this.filter.dates.start &&
        this.filter.dates.end
      ) {
        Object.entries(this.filter.dates).forEach(([key, value]) => {
          if (value) {
            copy.dates.push(value);
          }
        });
      } else {
        delete copy.dates;
      }

      return copy;
    },
    onSearch() {
      this.loading = true;
      this.makeSearch({
        routeKey: "discountCoupons",
        filter: this.getFilter(),
        onSuccess: (result) => {
          this.list = result.data.items;
          this.loading = false;
        },
      });
    },
    onExport(data) {
      if (this.list && this.list.length) {
        if (data.name !== "print") {
          let fileName = "Kupon Kodu Raporları";

          if (
            this.filter.dates &&
            this.filter.dates.start &&
            this.filter.dates.end
          ) {
            let startDate = new Date(
              this.filter.dates.start
            ).toLocaleDateString("tr-TR");
            let endDate = new Date(this.filter.dates.end).toLocaleDateString(
              "tr-TR"
            );

            fileName = startDate + " - " + endDate + " - " + fileName;
          }

          this.exportData({
            route: "report/discount-coupons/export",
            exportable: data,
            fileName: fileName,
            filter: {
              ...this.getFilter(),
              type: data.name,
            },
          });
        } else {
          this.setPrintableSettings(true);
          this.$refs.printButton.click();

          setTimeout(() => {
            this.setPrintableSettings(false);
          }, 100);
        }
      } else {
        this.$swal({
          title: "Dışarı aktarılacak veya Yazdırılacak veri mevcut değil",
          icon: "warning",
          confirmButtonText: "Tamam",
        });
      }
    },
    setPrintableSettings(print = false) {
      this.paginatable = !print;
      this.pageDetails = !print;
      this.limitable = !print;
      this.searchable = !print;

      this.list.sort();
    },
    onAction(data) {
      this.showDetail(data.key, data.row);
    },
    showDetail(key, row) {
      this.setDetail(row);

      this.modalLoading = true;
      if (key == "orders") {
        this.showOrderList = true;
      } else {
        this.showUserList = true;
      }

      let routeKey =
        key == "orders" ? "discountCouponOrders" : "discountCouponUsers";

      this.show({
        routeKey: routeKey,
        filter: this.getFilter(),
        id: row.id,
        onSuccess: (result) => {
          if (key == "orders") {
            this.setOrderList(result.data);
          } else {
            this.setUserList(result.data);
          }
        },
        onFinish: () => {
          this.modalLoading = false;
        },
      });
    },
    setDetail(row) {
      this.detail = {};
      this.detail.id = row.id;
      this.detail.discount_coupon_id = row.discount_coupon_id;
      this.detail.title =
        row.name !== row.code ? `${row.name} (${row.code})` : row.code;
      this.detail.filter = this.getFilter();
      this.detail.list = [];
    },
    onFilterClear() {
      this.filter = {
        dates: {
          start: null,
          end: null,
        },
        category: null,
      };
      this.onSearch();
    },
    setOrderList(data) {
      this.detail.list = data.items;
      this.detail.tableTotalPrice = data.total;
    },
    setUserList(data) {
      this.detail.list = data.items;
    },
  },
  computed: {
    ...mapState(["campaignReport"]),
    getFileName() {
      const defaultName = "Kupon Kodu Raporları";
      let result = "";

      if (
        this.filter.dates &&
        this.filter.dates.start &&
        this.filter.dates.end
      ) {
        let startDate = this.filter.dates.start;
        let endDate = this.filter.dates.end;

        startDate = new Date(startDate).toLocaleDateString("tr-TR");
        endDate = new Date(endDate).toLocaleDateString("tr-TR");

        result = `${startDate} - ${endDate} ${defaultName}`;
      } else {
        result = defaultName;
      }

      return result;
    },
  },
  mounted() {
    this.onSearch();
  },
};
</script>

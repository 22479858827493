<template>
  <WeModal v-on:close="onClose" v-bind:title="getTitle" modal-width="80">
    <div
      slot="body"
      class="pr-3 custom-scrollbar"
      style="max-height: 550px; overflow: auto"
    >
      <div style="min-height: 250px" v-if="loading">
        <WeLoading />
      </div>
      <div v-else v-bind:id="printConfig.id">
        <span v-print="printConfig" ref="printButton"> </span>
        <div class="text-center print">
          <h5 class="font-weight-bold">{{ getFileName }}</h5>
        </div>
        <WeTable
          v-bind:index="false"
          v-bind:columns="columns"
          v-bind:data="detail.list"
          v-bind:exportables="exportables"
          v-on:on-export="onExport"
          v-bind:paginatable="paginatable"
          v-bind:pageDetails="pageDetails"
          v-bind:limitable="limitable"
          v-bind:searchable="searchable"
          v-bind:show-table-total="true"
          v-bind:table-total="detail.tableTotalPrice"
          v-bind:hide-table-total-print="true"
          total-column="total_amount"
        />
        <div class="col-12 text-right" v-if="!paginatable">
          <span class="font-weight-bold"
            >Toplam Tutar : {{ detail.tableTotalPrice | moneyFormat }}
          </span>
        </div>
      </div>
    </div>
  </WeModal>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "OrderList",
  props: {
    detail: {
      default: null,
    },
    loading: {
      default: true,
    },
  },
  data() {
    return {
      columns: [
        {
          name: "created_at",
          th: "Sipariş Tarihi",
          type: "datetime",
        },
        {
          name: "order_no",
          th: "Sipariş Numarası",
          type: "string",
        },
        {
          name: "total_price",
          th: "Toplam Tutar",
          type: "currency",
        },
        {
          name: "total_discount",
          th: "İndirim Tutarı",
          type: "currency",
        },
        {
          name: "total_amount",
          th: "Net Tutar",
          type: "currency",
        },
      ],
      paginatable: true,
      pageDetails: true,
      limitable: true,
      searchable: true,
      exportables: ["excel", "pdf", "print"],
      printConfig: {
        id: "DiscountCouponOrders",
        popTitle: "Kupon Kodu Sipariş Listesi",
      },
    };
  },
  methods: {
    ...mapActions("tableExport", ["exportData"]),
    onClose() {
      this.$emit("close");
    },
    onExport(data) {
      if (this.detail.list && this.detail.list.length) {
        if (data.name !== "print") {
          this.exportData({
            route: `report/discount-coupons/${this.detail.id}/orders/export`,
            exportable: data,
            fileName: this.getFileName,
            filter: {
              ...this.detail.filter,
              type: data.name,
            },
          });
        } else {
          this.setPrintableSettings(true);
          this.$refs.printButton.click();

          setTimeout(() => {
            this.setPrintableSettings(false);
          }, 100);
        }
      } else {
        this.$swal({
          title: "Dışarı aktarılacak veya Yazdırılacak veri mevcut değil",
          icon: "warning",
          confirmButtonText: "Tamam",
        });
      }
    },
    setPrintableSettings(print = false) {
      this.paginatable = !print;
      this.pageDetails = !print;
      this.limitable = !print;
      this.searchable = !print;

      this.detail.list.sort();
    },
  },
  computed: {
    getTitle() {
      return this.detail.title + " - Sipariş Listesi";
    },
    getFileName() {
      const defaultName = this.detail.title + " Sipariş Litesi";
      let result = "";

      if (this.detail.filter.dates) {
        let startDate = this.detail.filter.dates[0];
        let endDate = this.detail.filter.dates[1];

        startDate = new Date(startDate).toLocaleDateString("tr-TR");
        endDate = new Date(endDate).toLocaleDateString("tr-TR");

        result = `${startDate} - ${endDate}_${defaultName}`;
      } else {
        result = defaultName;
      }

      return result;
    },
  },
};
</script>